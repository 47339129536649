import { Mark } from '@tiptap/core'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { Bold } from 'lucide-react'
import React from 'react'
import { Toggle } from '#app/components/ui/toggle.tsx'

const CustomClassMark = Mark.create({
	name: 'customClass',

	addAttributes() {
		return {
			class: {
				default: null,
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: 'span[class]',
				getAttrs: node => ({
					class:
						node instanceof HTMLElement ? node.getAttribute('class') : null,
				}),
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', HTMLAttributes, 0]
	},
})

export const WodamigoTiptapEditor = ({
	textareaProps,
	onChange,
}: {
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	onChange: (value: string) => void
}) => {
	const editor = useEditor({
		extensions: [StarterKit, CustomClassMark],
		content: textareaProps.defaultValue?.toString() ?? '',
		editorProps: {
			attributes: {
				class: 'p-4',
			},
		},
		onUpdate({ editor }) {
			onChange(editor.getHTML())
		},
	})

	const toggleClass = ({ cls }: { cls: string }) => {
		if (!editor) {
			return
		}
		const isActive = editor.isActive('customClass', { class: cls })
		if (isActive) {
			editor.chain().focus().unsetMark('customClass').run()
		} else {
			editor.chain().focus().setMark('customClass', { class: cls }).run()
		}
	}

	if (!editor) {
		return null
	}

	return (
		<div className="rounded-md border border-gray-200 bg-background dark:border-gray-700">
			<div className={'p-4'}>
				<Toggle
					variant={'outline'}
					onClick={() => toggleClass({ cls: 'font-bold' })}
				>
					<Bold size="18" />
					<span className={'ml-2 inline-block'}>Bold</span>
				</Toggle>
			</div>
			<EditorContent editor={editor} />
		</div>
	)
}
