import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import * as React from 'react'

import { Button } from '#/app/components/ui/button'
import { Calendar } from '#/app/components/ui/calendar'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '#/app/components/ui/popover'
import { cn } from '#app/utils/misc.tsx'

export function DatePicker({
	date,
	setDate,
}: {
	date: Date | undefined
	setDate: (date: Date | undefined) => void
}) {
	const buttonRef = React.useRef<HTMLButtonElement>(null)
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={'outline'}
					className={cn(
						'w-[280px] justify-start text-left font-normal',
						!date && 'text-muted-foreground',
					)}
					ref={buttonRef}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{date ? format(date, 'dd. MMMM yyyy') : <span>Pick a date</span>}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={date => {
						if (date) {
							date.setHours(0, 0, 0, 0)
							setDate(date)
						}
						buttonRef.current?.click()
					}}
				/>
			</PopoverContent>
		</Popover>
	)
}
